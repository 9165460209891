// Scale desktop...
@function sd($pixels, $scale: $scale-desktop) { // sorry for the acronym, but will probably be used everywhere to save time
  @return $pixels * $scale;
}

// converts px to rem
@function rem($size) {
  $rem-size: $size / 16;
  @return #{$rem-size}rem;
}

// returns vertical rhythm 'row' height
@function vr($modifier) {

  // define variables used in vertical rhythm mixin
  $modular-scale-ratio: 1.5;
  $em-base: 16;

  @return rem(($modular-scale-ratio * $em-base) * $modifier);
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

// just making the sass function a bit shorter
@function percent($number, $context: $width-content) {
  @return percentage(strip-unit($number)/strip-unit($context));
}

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  
  @return $string;
}

@function str-contains($string, $search) {
	@if (str-index($string, $search)) {
		@return true;
	} @else {
    @return false;
  }
}

// Make things responsive to the context... useful in media queries
@function resp-w($pixels, $context: $width-content) {
  $vw-context: (strip-unit($context) * .1) * 1px;
  $responsive: ($pixels / $vw-context) * 10vw;

  @return $responsive;
}

@function resp-h($pixels, $context: $height-content) {
  $vh-context: (strip-unit($context) * .1) * 1px;
  $responsive: ($pixels / $vh-context) * 10vh;

  @return $responsive;
}

@function url-encode($string) {
  $map: (
   "%": "%25",
   "<": "%3C",
   ">": "%3E",
   " ": "%20",
   "!": "%21",
   "*": "%2A",
   "'": "%27",
   '"': "%22",
   "(": "%28",
   ")": "%29",
   ";": "%3B",
   ":": "%3A",
   "@": "%40",
   "&": "%26",
   "=": "%3D",
   "+": "%2B",
   "$": "%24",
   ",": "%2C",
   "/": "%2F",
   "?": "%3F",
   "#": "%23",
   "[": "%5B",
   "]": "%5D"
   );
  $new: $string;
  @each $search, $replace in $map {
    $new: str-replace($new, $search, $replace);
  }
  @return $new;
}
@function inline-svg($string) {
  @return url('data:image/svg+xml;charset=US-ASCII,#{url-encode($string)}');
}