@font-face {
  font-family: 'Oleo Script';
  font-weight: 400;
  font-display: block;
  src: local('Oleo Script'), local('OleoScript'),  local('Oleo Script Regular'), local('OleoScript-Regular'), url('/static/font/oleo/OleoScript-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Oleo Script';
  font-weight: 700;
  font-display: block;
  src: local('Oleo Script Bold'), local('OleoScript-Bold'), url('/static/font/oleo/OleoScript-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Galano Grotesque';
  font-weight: 400;
  font-display: fallback;
  src: local('Galano Grotesque'), local('GalanoGrotesque'), local('Galano Grotesque Regular'), local('GalanoGrotesque-Regular'), url('/static/font/galano/GalanoGrotesque.woff') format('woff');
}

@font-face {
  font-family: 'Galano Grotesque';
  font-weight: 500;
  font-display: fallback;
  src: local('Galano Grotesque Medium'), local('GalanoGrotesque-Medium'), url('/static/font/galano/GalanoGrotesque-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Galano Grotesque';
  font-weight: 700;
  font-display: fallback;
  src: local('Galano Grotesque Bold'), local('GalanoGrotesque-Bold'), url('/static/font/galano/GalanoGrotesque-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Press Start 2P';
  font-display: block;
  src: local('Press Start 2P'), local('PressStart2P'), local('Press Start 2P Regular'), local('PressStart2P-Regular'), url('/static/font/pressstart2p/PressStart2P-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Cubic Five';
  font-weight: 400;
  font-display: block;
  src: local('Cubic Five'), local('CubicFive'), local('Cubic Five Regular'), local('CubicFive-Regular'), url('/static/font/cubicfive/CubicFive.woff') format('woff');
}

@font-face {
  font-family: 'Visitor';
  font-display: block;
  src: local('Visitor'), local('Visitor Regular'), local('Visitor-Regular'), url('/static/font/visitor/Visitor.ttf') format('truetype');
}