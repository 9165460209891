// Colors
$color-red: #F7567C;
$color-green: #97C9BE;
$color-latte: #FFFAE3;
$color-coal: #5D576B;
$color-powder: #FCFCFC;
$color-blue: #90AEFA;

$color-white: #fff;
$color-black: #000;

// Type
$family-galano: 'Galano Grotesque', 'Droid Sans', 'Helvetica Neue', sans-serif;
$famliy-olea: 'Oleo Script';
$famliy-presstart2p: 'Press Start 2P', $family-galano;
$famliy-cubicfive: 'Cubic Five', $family-galano;
$famliy-visitor: 'Visitor', $family-galano;

$family-primary: $family-galano;
$family-secondary: $famliy-olea;
$famliy-retro: $famliy-cubicfive;

// Dimensions
$width-mobile: 320px;
$width-phablet: 600px;
$width-tablet: 768px;
$width-after-tablet: 810px;
$width-tablet-landscape: 1025px;
$width-widescreen: 1270px; 

$width-content: 1240px;
$width-gutter: 30px;

$height-content: 700px;