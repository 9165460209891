// Sprites... replacing the sprite-smith generated mixin in order to generate animations
@mixin sprites($sprites) {
	$first-sprite: ();
	$previous-name: '';
	$previous-position: 0px;
	$frame-count: 0;

	// Loop through sprite object variables
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);

    // If sprite name has 'anim' inside it's name, then treat as animation
    @if str-contains($sprite-name, '_anim_') {
    	// Strip out frame numbers from name, since we don't care about those
	    $sprite-name: str-replace($sprite-name, '_f0');
	    $sprite-name: str-replace($sprite-name, '_f1');
	    $sprite-name: str-replace($sprite-name, '_f2');
	    $sprite-name: str-replace($sprite-name, '_f3');

    	// If same animation then just increment the frame count for now
    	@if $sprite-name == $previous-name {
    		$frame-count: $frame-count + 1;
    	} @else {
    		// If moving onto new sprite now, need to use previously stored sprite info... only if we have more than 1 frame
    		@if $frame-count > 1 {
					// Generate the class for the sprite animation, now that we know everything else...
		    	.sprite--#{$previous-name} {
		    		@include sprite($first-sprite);
		    		animation: sprite--#{$previous-name}-forwards #{$frame-count * 175}ms steps($frame-count) infinite;
		    	}
    			// Generate the animation, now that we know when the animation actually stops...
		    	@keyframes sprite--#{$previous-name}-forwards {
					0% { background-position: nth($first-sprite, 3) 0px; }
					100% { background-position: $previous-position 0px; }
				}
				@keyframes sprite--#{$previous-name}-backwards {
					0% { background-position: $previous-position 0px; }
					100% { background-position: nth($first-sprite, 3) 0px; }
				}
    		}

	    	// Reset first sprite and frame count
	    	$first-sprite: $sprite;
	    	$frame-count: 0;
    	}

	    $previous-name: $sprite-name;
	    $previous-position: nth($sprite, 3);
    } @else {
	    .sprite--#{$sprite-name} {
	      @include sprite($sprite);
	    }
    }
  }
}

// clearfix
@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// media query mixin... might not use, sometimes max-width is just needed
@mixin media($width, $at: 'min-width') {
  @media (#{$at}: $width) {
    @content;
  }
}

// media query mixin
@mixin retina() {
  @media
  (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
      @content;
  }
}

@mixin font-smoothing($value: on) {
  @if $value == on {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  } @else {
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: subpixel-antialiased;
  }
}

// Inline underline
@mixin inline-underline($color: $color-primary, $size: 17%) {
  background-image: linear-gradient(to right, $color 0%, $color 100%);
  background-repeat: repeat-x; // make it horizontal
  background-position: 0 105%; // change the second value to adjust how far from the top the underline should be
  background-size: 100% $size; // change the second value to the desired height of the underline
}

// === Scaling
// Viewport sized typography mixin that takes a min and max pixel-based value
@mixin font-scale($min, $max, $context: $width-content) {
  // font-size: $max;
  @include scale(font-size, $min, $max, $context);
}

@mixin scale($property, $min, $max, $context: $width-content) {

  $vw-context: (strip-unit($context) * .1) * 1px;
  $responsive: ($max / $vw-context) * 10vw;

  $responsive-unitless: strip-unit($responsive);
  $dimension: if(unit($responsive) == 'vh', 'height', 'width');
  $min-breakpoint: $min / $responsive-unitless * 100;

  @media (max-#{$dimension}: #{$min-breakpoint}) {
    #{$property}: $min;
  }

  $max-breakpoint: $max / $responsive-unitless * 100;

  @media (min-#{$dimension}: #{$max-breakpoint}) {
    #{$property}: $max;
  }

  #{$property}: $responsive;
}

// Mixing a little bit of scale with fixed breakpoints...
@mixin scale-accurate($property, $mobileSize, $width-tabletSize, $desktopSize, $widescreenSize) {
  // Scale from mobile...
  #{$property}: $mobileSize;

  // Scale from tablet
  @include media($width-tablet) {
    #{$property}: $width-tabletSize;
  }

  // Scale to widescreen...
  @include media($width-desktop) {
    #{$property}: $desktopSize;
  }

  @include media($width-widescreen) {
    #{$property}: $widescreenSize;
  }
}

// === Loading Spinner stuff
@mixin loading-spinner($color: $color-primary, $size: 21px, $thickness: 6) {
  $svg: '<svg viewBox="-#{($thickness / 2)-1} -#{($thickness / 2)-1} #{36 + $thickness} #{36 + $thickness}" xmlns="http://www.w3.org/2000/svg" stroke="#{$color}"><g fill="none" fill-rule="evenodd"><g transform="translate(1 1)" stroke-width="#{$thickness}"><circle stroke-opacity=".5" cx="18" cy="18" r="18"/><path d="M36 18c0-9.94-8.06-18-18-18"></path></g></g></svg>';
  content: "";
  position: absolute;
  height: $size + 1;
  width: $size;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg) ;
  background-image: #{inline-svg($svg)};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  animation: spin 1s linear infinite;
}

@keyframes spin { 100% { transform: translate(-50%, -50%) rotate(360deg); } }


// Burger parts
//
// (---) top    -> &::before
// [---] middle -> &
// (---) bottom -> &::after

// Vendor prefixes
$sass-burger-add-vendor-prefixes: true !default;

// Burger
@mixin burger($width: 30px, $height: 5px, $gutter: 3px, $color: #000, $border-radius: 0, $transition-duration: .3s) {
  $burger-height: $height !global;
  $burger-gutter: $gutter !global;

  position: relative;
  margin-top: $height + $gutter;
  margin-bottom: $height + $gutter;

  @if $sass-burger-add-vendor-prefixes {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  user-select: none;

  // 1. Fixes jagged edges in Firefox, see issue #10.
  &, &::before, &::after {
    display: block;
    width: $width;
    height: $height;
    background-color: $color;
    outline: 1px solid transparent; // 1
    @if $border-radius != 0 {
      border-radius: $border-radius;
    }

    @if $sass-burger-add-vendor-prefixes {
      -webkit-transition-property: background-color, -webkit-transform;
      -moz-transition-property: background-color, -moz-transform;
      -o-transition-property: background-color, -o-transform;
    }
    transition-property: background-color, transform;

    @if $sass-burger-add-vendor-prefixes {
      -webkit-transition-duration: $transition-duration;
      -moz-transition-duration: $transition-duration;
      -o-transition-duration: $transition-duration;
    }
    transition-duration: $transition-duration;
  }

  &::before, &::after {
    position: absolute;
    content: "";
  }

  &::before {
    top: -($height + $gutter);
  }

  &::after {
    top: $height + $gutter;
  }
}


// Select parts of the burger
@mixin burger-parts {
  &, &::before, &::after {
    @content;
  }
}

@mixin burger-top {
  &::before {
    @content;
  }
}

@mixin burger-middle {
  & {
    @content;
  }
}

@mixin burger-bottom {
  &::after {
    @content;
  }
}


// Burger animations
@mixin burger-to-cross($color: auto) {
  & {
    background-color: transparent;
  }
  @if ($color != auto) {
    &::before, &::after {
      background-color: $color;
    }
  }
  &::before {
    @if $sass-burger-add-vendor-prefixes {
      -webkit-transform: translateY($burger-gutter + $burger-height) rotate(45deg);
      -moz-transform: translateY($burger-gutter + $burger-height) rotate(45deg);
      -ms-transform: translateY($burger-gutter + $burger-height) rotate(45deg);
      -o-transform: translateY($burger-gutter + $burger-height) rotate(45deg);
    }
    transform: translateY($burger-gutter + $burger-height) rotate(45deg);
  }
  &::after {
    @if $sass-burger-add-vendor-prefixes {
      -webkit-transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
      -moz-transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
      -ms-transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
      -o-transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
    }
    transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
  }
}