@import "init/index";

@import "base/fontface";
@import "base/button";
@import "base/transitions";
@import "base/type";

@import "components/Game";

@include sprites($spritesheet-sprites);

*, *:after, *:before {
  box-sizing: border-box;
}

img {
  width: auto;
  max-width: 100%;
}

hr {
  position: relative;
  border: none;
  padding: 30px 0;
  margin: 0;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    width: 100%;
    max-width: 495px;
    margin: auto;
    background: $color-coal;
    opacity: 0.9;
  }

  .transition & {
    &:after {
      max-width: 0;
      transition: max-width 1000ms ease-in-out;
    }
  }

  .transit & {
    &:after {
      max-width: 495px;
    }
  }
}

input, textarea {
  display: block;
  width: 100%;
  color: $color-white;
  border: 1px solid $color-white;
  border-radius: 5px;
  font-family: $family-primary;
  font-size: 16px;
  margin-bottom: 15px;
  padding: 6px 10px 6px;
  appearance: none;
  background: transparent;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px $color-blue inset !important;
    -webkit-text-fill-color: $color-white !important;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: $color-white;
  }
}

textarea {
  height: 170px;
}

.container {
  // max-height: 100vh;
  // overflow-y: scroll;
  // scroll-snap-type: y;
}

.section {
  text-align: center;
  scroll-snap-align: start;
}

.section--green {
  background-color: $color-green;
  color: $color-white;
}

.section--blue {
  background-color: $color-blue;
  color: $color-white;
}

.section--latte {
  background-color: $color-latte;
  color: $color-coal;
}

.section__inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: ($width-content + $width-gutter);
  min-height: 100vh;
  padding: resp-h(60px) ($width-gutter / 2);
  width: 100%;
  margin: auto;
}

.section__heading {
  @include scale(margin-bottom, 20px, 25px);
}

.section__subheading {
  @include scale(margin-top, -24px, -32px);
  margin-bottom: resp-h(20px);
}

// Profile
.profile {
  max-width: 600px;
}

.profile__image {
  @include scale(max-width, 160px, 210px);
  margin-bottom: resp-h(20px);
}

.profile__body {
  margin-bottom: resp-h(40px);
}

.profile__actions {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: $width-tablet + 1) {
    .playing & {
      transition: opacity 175ms, transform 175ms, visibility 0ms 250ms;
      transform: scale(0.9);
      opacity: 0;
      visibility: hidden;
    }
  }
}

.profile__decision {
  position: relative;
  display: none;
  min-width: 60px;
  font-size: 14px;
  margin-top: -2px;
  margin-left: 8px;
  margin-right: 8px;

  &:before, &:after {
    content: "";
    position: absolute;
    top: 50%;
    height: 1px;
    background-color: $color-white;
  }

  &:before {
    right: calc(50% + 15px);
    left: calc(50% + 15px);
  }

  &:after {
    left: calc(50% + 15px);
    right: calc(50% + 15px);
  }

  @media (min-width: $width-tablet + 1) {
    display: block;

    .transition & {
      &:before, &:after {
        transform: rotate(180deg);
        transition: left 1000ms ease-in-out 400ms, right 1000ms ease-in-out 400ms;
      }
    }


    .transit & {
      &:before {
        left: 0;
      }

      &:after {
        right: 0;
      }
    }
  }
}

.profile__action {

}

.profile__action--portfolio {

}

.profile__action--play {
  display: none;

  @media (min-width: $width-tablet + 1) {
    display: block;
  }
}

// Portfolio
.portfolio {
  width: 100%;

  @media (min-width: $width-tablet + 1) {
    transition: padding 1s;
    
    .playing & {
      padding-right: 250px;
    }
  }
}

.portfolio__article {
  display: flex;
  margin: 30px -#{$width-gutter / 2} 14px -#{$width-gutter / 2};
  flex-direction: column;

  @media (min-width: $width-phablet) {
    margin: 120px -#{$width-gutter / 2};
    flex-direction: row;

    &:nth-of-type(2n) {
      flex-direction: row-reverse;
    }
  }
}

.portfolio__column {
  margin: 0 ($width-gutter / 2);

  @media (min-width: $width-phablet) {
    width: 600px;
    max-width: 50%;
  }
}

.portfolio__image {
  img {
    display: block;
    border-radius: 5px;
    opacity: 0.8;
    margin-bottom: 10px;
  }
}

.portfolio__details {
  margin: auto;
  text-align: left;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 1em;
  }

  @media (min-width: $width-phablet) {
    max-width: 480px;
  }
}

.portfolio__heading {
  margin-top: 15px;

  @media (min-width: $width-phablet) {
    margin-top: 30px;

    .transition:first-child & {
      margin-top: 0;
    }
  }
}


// Contact
.contact {

}

.contact__body {
  margin: auto;
  margin-bottom: 55px;
  font-size: 24px;
  @include font-scale(20px, 24px);
  max-width: 600px;
}

.contact__form {
  max-width: 380px;
  margin: auto;
  text-align: left;

  .button {
    max-width: none;

    @media (min-width: $width-phablet) {
      max-width: 180px;
      min-width: 180px;
      margin-right: $width-gutter;
    }
  }
  
  .button__label--error {
    bottom: 100%;
  }

  .button__label--success {
    top: 100%;
  }

  .loading &, .success &, .error & {
    .button {
      @include button-pressed();
      pointer-events: none;
    }
  }

  .error & {
    .button__label {
      transform: translateY(100%);
    }
  }

  .success & {
    .button__label {
      transform: translateY(-100%);
    }
  }
}

.contact__submission {
  @media (min-width: $width-phablet) {
    display: flex;
  }
}

.contact__response {
  margin-top: 15px;
  text-align: center;
  font-size: 13px;
  opacity: 0;
  transition: opacity 175ms;

  .success &, .error & {
    opacity: 1;
  }

  @media (min-width: $width-phablet) {
    margin-top: 0;
    text-align: left;

    & > div {
      position: absolute;
    }
  }
}




// Sprite overrides
.sprite--floor_spikes_anim {
  animation-duration: 100ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-name: sprite--floor_spikes_anim-backwards;

  &.triggered {
    animation-name: sprite--floor_spikes_anim-forwards;
  }
}

.sprite--doors_small_frame_left, .sprite--doors_small_frame_right, .sprite--doors_small_leaf_closed, .sprite--doors_small_leaf_open {
  transform: translateY(-50%);
}