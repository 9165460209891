// Base
.game {
  display: none;
  position: relative;
  width: 432px;
  position: absolute;
  right: 0;
  top: 100%;
  margin-top: -144px;
  z-index: 5;

  @media (min-width: $width-tablet + 1) {
    display: block;
  }
}

// Credits
.game__credits {
  position: absolute;
  bottom: 100%;
  width: 100%;
  margin-bottom: -10px;
  z-index: 99;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 11px;
    text-align: left;
    display: flex;
    align-items: flex-end;
  }

  li {
    width: 100%;
  }


}

.game__close {
  width: auto !important;
  padding-right: 17px;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    font-size: 16px;
    line-height: 1;
    background: $color-white;
    color: $color-green;
    border-radius: 100px;
    cursor: pointer;
  }
}

// Instructions
.game__instructions {
  position: absolute;
  top: 20px;
  right: 100%;
  width: 325px;
  display: flex;
  margin-right: 10px;
}

.game__instruction {
  font-size: 14px;
  padding: 0 10px;

  img {
    height: 60px;
    display: block;
    margin: auto;
    margin-bottom: 10px;
  }
}

// Entities
.game__entity {
  position: absolute;
  transform: translate(-50%, -85%);
  z-index: 5;
  transition: top 175ms linear, left 175ms linear, opacity 700ms linear 175ms;

  &.hit {
    filter: invert(1);
    opacity: 0.5;
  }

  &.dying {
    transition-delay: 0;
    filter: invert(1);
    opacity: 0;
    animation: none;
  }

  &.mirrored {
    transform: translate(-50%, -85%) scaleX(-1);
  }
}

.game__player {
  &.sprite--knight_m_run_anim {
    margin-top: 1px;
  }
}

.game__hearts {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.game__player-weapon-holder {
  position: absolute;
  left: 50%;
  top: 50%;
  transform-origin: bottom center;

  .sprite--knight_m_run_anim & {
    margin-top: -2px;
  }
}

.game__player-weapon--hitting {
  transform-origin: bottom center;
  animation: weapon-slash 200ms ease-in-out;

  .dying & {
    animation: none;
  }
}

@keyframes weapon-slash {
  0% {
    transform: rotate(-50deg);
  }
  50% {
    transform: rotate(30deg);
  }
}

.game__monster {
  position: absolute;
  z-index: 3;
}

// Tiles
.game__tiles {
}

.game__tiles-row {
  display: flex;
}

.game__tiles-row--overlay {
  position: absolute;
}

.game__tile {
  position: absolute;
  font-size: 6px;
}

.game__reward {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  animation-duration: 1.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-name: reward;
}

@keyframes reward {
  0% {
    opacity: 0;
    bottom: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    bottom: 100%;
  }
}

.game__ending {
  position: absolute;
  top: 960px;
  width: 233px;
  right: 15px;
  padding: 15px;
  color: $color-coal;
}

.game__decision {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
}

.game__action {
  margin: 10px;
}