@mixin button-raised() {
	box-shadow: 0px 3px 0px rgba($color-black, 0.3);
	transform: translateY(-1px);
	transition: box-shadow 175ms, transform 175ms;
}

@mixin button-pressed() {
	box-shadow: 0px 1px 0px rgba($color-black, 0.3);
	transform: translateY(1px);
	transition: box-shadow 75ms, transform 75ms;
}

.button {
	position: relative;
	background: rgba($color-white, 0.15);
	border: none;
	overflow: hidden;
	color: $color-white;
	text-transform: uppercase;
	border-radius: 5px;
  	@include font-scale(14px, 15px);
	line-height: 1.2;
	width: 100%;
	max-width: 240px;
	box-shadow: 0px 2px 0px rgba($color-black, 0.3);
	font-family: $family-primary;
	font-weight: 500;
	outline: none;
	transition: box-shadow 175ms, transform 175ms;
	cursor: pointer;

	svg, img {
		position: absolute;
		max-height: 20px;
		fill: currentColor;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&:hover {
		@include button-raised();
	}

	&:active {
		@include button-pressed();
	}

	&:after {
		display: none;
	}
}

.button--dark {
	background: rgba($color-coal, 0.15);
	color: $color-coal;
}

.button--retro {
  font-family: $famliy-retro;
  font-weight: 400;

	.button__label--default {
		// padding: 10px 12px 13px;
	}
}

.button__label {
	display: block;
  transition: transform 250ms;
}

.button__label--default {
	padding: 11px 12px 12px;
}

.button__label--hover, .button__label--error, .button__label--success {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
}

.button__label--hover {
	bottom: 100%;

	.button:hover &, .button:hover & + .button__label--default {
		transform: translateY(100%);
	}
}