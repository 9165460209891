.transition {
}

.transition--fadetoright {

}

.transition--fadetoleft {

}

.transition--fadetoup {
	opacity: 0;
	transform: translateY(30px);
	transition: opacity 650ms ease-out, transform 650ms ease-out;

	&.transit, .playing & {
		opacity: 1;
		transform: translateY(0);
	}
}

.transition--fadescale {
	opacity: 0;
	transform: scale(0.8);
	transition: opacity 650ms ease-out, transform 650ms ease-out;

	&.transit, .playing & {
		opacity: 1;
		transform: scale(1);
	}
}