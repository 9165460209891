body {
  margin: 0;
  padding: 0;
  font-family: $family-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  line-height: 1.5;
  font-size: 18px;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 400;
  margin: 0;
}

h1, .h1 {
  font-family: $family-secondary;
  font-weight: 400;
  @include font-scale(38px, 60px);
  transform: none;
}

h2, .h2 {
  @include font-scale(22px, 30px);
}

h3, .h3 {
  @include font-scale(21px, 26px);
  font-weight: 500;
  margin-bottom: 5px;
}

p {
  margin: 0;
  margin-bottom: 1em;
}

a {
  position: relative;
  color: inherit;
  text-decoration: none;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 1px;
    width: 100%;
    height: 1px;
    background-color: currentColor;
  }
}